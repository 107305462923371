<template>
  <v-container fluid>
    <v-row>
      <v-col v-if="loading" cols="12">
        <v-progress-circular
            indeterminate
            color="primary"
        ></v-progress-circular>
      </v-col>
      <v-col cols="12" v-else>
        <v-container fluid class="pa-0">
          <v-row>
            <v-col class="col-12 col-md-4">
              <v-container fluid class="pa-0">
                <v-row>
                  <v-col
                  ><v-btn @click="edit_cp" v-if="$auth.check('superadmin')">
                    {{ $t("points.edit") }}
                  </v-btn></v-col
                  >
                  <v-col
                  ><v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <h4
                          class="d-inline-block ml-4 red--text"
                          v-if="point.testing_app"
                          v-bind="attrs"
                          v-on="on"
                      >
                        {{ $t("points.test_point") }}
                      </h4>
                    </template>
                    <span>{{ $t("points.test_point_info") }}</span>
                  </v-tooltip></v-col
                  >
                  <v-col
                  ><v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                          :block="$vuetify.breakpoint.mobile"
                          v-bind="attrs"
                          v-on="on"
                          :class="[
                            isOnline ? 'online' : 'offline',
                            isOnline_text,
                          ]"
                      >{{ point_status }}</v-btn
                      >
                    </template>
                    <span>{{ $t("points.point_info") }}</span>
                  </v-tooltip></v-col
                  >
                </v-row>
              </v-container>
            </v-col>
            <v-spacer></v-spacer>
            <v-col class="col-12 col-md-4">
              <v-row align="center" justify="space-around" class="pt-3">
                <v-btn
                    color="primary"
                    link
                    :block="$vuetify.breakpoint.mobile"
                    :to="{
                    name: 'point_config',
                    param: this.$route.params.point_id,
                  }"
                >{{ $t("points.settings") }}</v-btn>
<!--                <v-tooltip bottom>-->
<!--                  <template v-slot:activator="{ on, attrs }">-->
<!--                    <v-btn-->
<!--                        v-bind="attrs"-->
<!--                        v-on="on"-->
<!--                        :color="btn_color"-->
<!--                        class="white&#45;&#45;text"-->
<!--                        @click="show_point"-->
<!--                        :block="$vuetify.breakpoint.mobile"-->
<!--                    >{{ btn_text }}-->
<!--                    </v-btn>-->
<!--                  </template>-->
<!--                  <span>{{ $t("points.change_mobile") }}</span>-->
<!--                </v-tooltip>-->
<!--                <v-menu offset-y>-->
<!--                  <template v-slot:activator="{ on, attrs }">-->
<!--                    <v-btn-->
<!--                        v-if="$auth.check('superadmin')"-->
<!--                        color="blue-grey lighten-3"-->
<!--                        v-bind="attrs"-->
<!--                        v-on="on"-->
<!--                        :block="$vuetify.breakpoint.mobile"-->
<!--                    >-->
<!--                      {{ $t("points.commands") }}-->
<!--                    </v-btn>-->
<!--                  </template>-->
<!--                  <v-list>-->
<!--                    <v-list-item v-for="(item, index) in commands" :key="index">-->
<!--                      <v-list-item-title-->
<!--                          @click="show_command(item)"-->
<!--                          class="clickable"-->
<!--                      >{{ item.text }}</v-list-item-title-->
<!--                      >-->
<!--                    </v-list-item>-->
<!--                  </v-list>-->
<!--                </v-menu>-->
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="hidden-sm-and-down col-md-3">
              <div ref="googleMap" id="map"></div>
            </v-col>
            <v-col class="col-12 col-md-9">
              <v-card-text class="pa-0">
                <v-row>
                  <v-col class="col-12 col-md-3">
                    <span
                    >{{ $t("points.name") }}:
                      <b>{{ this.point.name }}</b></span
                    >
                  </v-col>
                  <v-col class="col-12 col-md-3">
                    <span
                    >{{ $t("points.vendor") }}:
                      <b>{{ this.point.vendor }}</b></span
                    >
                  </v-col>
                  <v-col class="col-12 col-md-3">
                    <span
                    >{{ $t("points.address") }}:
                      <b>{{
                          this.$store.getters.LOCALE == "ru"
                              ? this.point.address
                              : this.point.address_en
                        }}</b></span
                    >
                  </v-col>
                  <v-col class="col-12 col-md-3">
                    <span
                    >{{ $t("points.city") }}:
                      <b>{{ this.point.city }}</b></span
                    >
                  </v-col>
                  <v-col class="col-12 col-md-3">
                    <span
                    >{{ $t("points.serial_number") }}:
                      <b>{{ this.point.serial }}</b>
                    </span>
                  </v-col>
                  <v-col class="col-12 col-md-3">
                    <span
                    >{{ $t("points.model") }}:
                      <b>{{ this.point.model }}</b></span
                    >
                  </v-col>
                  <v-col class="col-12 col-md-3">
                    <span
                    >{{ $t("points.max_power") }}:
                      <b>{{ this.point.evse[0].power }}</b></span
                    >
                  </v-col>
                  <v-col class="col-12 col-md-3">
                    <span
                    >{{ $t("points.firmware") }}:
                      <b>{{ this.point.firmware }}</b></span
                    >
                  </v-col>
                  <v-col class="col-12 col-md-3">
                    <span
                    >{{ $t("points.total_energy") }}:
                      <b>{{ this.point.energy_total / 1000 }} кВт</b></span
                    >
                  </v-col>
                  <v-col class="col-12 col-md-3">
                    <span
                    >{{ $t("points.last_sync") }}:
                      <b>{{ updated_time }}</b></span
                    >
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" class="">
                    <h4 class="">{{ $t("points.connectors") }}</h4>
                    <template v-for="(evse, id) in this.point.evse">
                      <div class="pl-1" :key="id">
                        <template
                            v-for="(connector, con_id) in evse.connectors"
                        >
                          <div class="d-inline-flex" :key="con_id">
                            <div class="icon">
                              <div class="sprite outlet">
                                <div
                                    v-bind:class="
                                    connector_img(connector.connector_type)
                                  "
                                ></div>
                              </div>
                            </div>
                            <div class="px-2 d-inline-block mt-auto mb-auto">
                              <h4 class="">
                                {{
                                  String(connector.connector_type)
                                      .charAt(0)
                                      .toUpperCase() +
                                  String(connector.connector_type).slice(1)
                                }}
                              </h4>
                              <span>{{ con_text(connector) }}</span>
                            </div>
                          </div>
                        </template>
                      </div>
                    </template>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="col-12 col-md-6">
                    <v-menu
                        v-model="menu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            hide-details
                            v-model="dateFormatted"
                            :label="$t('points.date')"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            @blur="date"
                            v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                          v-model="date"
                          locale="ru-RU"
                          first-day-of-week="1"
                          @input="menu = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col class="col-12 col-md-6">
                    <v-select
                        hide-details
                        :items="filter_select"
                        v-model="event_filter"
                        item-text="text"
                        item-value="value"
                        clearable
                        :label="type_filter_text"
                    ></v-select>
                  </v-col>
                  <v-col cols="12">
                    <v-tabs
                        v-model="current_tab"
                        background-color="transparent"
                        color="basil"
                        grow
                    >
                      <v-tab v-for="(item, index) in tabs" :key="index">
                        {{ item.title }}
                      </v-tab>
                    </v-tabs>
                    <v-tabs-items v-model="current_tab">
                      <v-tab-item key="events">
                        <v-data-table
                            :headers="events_headers"
                            :items="point_events"
                            :no-data-text="$t('points.events_not_found')"
                            hide-default-header
                            :items-per-page="25"
                            :footer-props="{
                            'items-per-page-options': [10, 25, 50, 100, -1],
                          }"
                        >
                          <template v-slot:item.charging="{ item }">
                            <template v-if="item.charging == null"></template>
                            <template v-else>
                              <v-btn
                                  :to="{
                                  name: 'charging_info',
                                  params: { charge_id: item.charging },
                                }"
                              >{{ $t("points.more") }}</v-btn
                              >
                            </template>
                          </template>
                          <template v-slot:item.event_type="{ item }">
                            {{
                              charging_status(
                                  item,
                                  item.connector_id,
                                  item.error_code
                              )
                            }}
                          </template>
                        </v-data-table>
                      </v-tab-item>
                      <v-tab-item key="commands">
                        <v-container fluid>
                          <v-row>
                            <v-col cols="12">
                              <v-data-table
                                  :headers="command_headers"
                                  :items="point_command"
                                  :no-data-text="$t('points.commands_not_found')"
                                  hide-default-header
                                  :items-per-page="25"
                                  :footer-props="{
                                  'items-per-page-options': [
                                    10, 25, 50, 100, -1,
                                  ],
                                }"
                              >
                                <template v-slot:item.command="{ item }">
                                  {{ command_status(item) }}
                                </template>
                              </v-data-table>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-tab-item>
                    </v-tabs-items>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import moment from "moment";

export default {
  name: "PointInfo",
  components: {
  },
  mounted() {
  },
  created() {
    this.load_params();
    this.load_point();
  },
  data: () => {
    return {
      loading: false,
      menu: false,
      maps_inited: false,
      date: null,
      dateFormatted: null,
      event_filter: null,
      filter_type: "event",
      current_tab: 0,
      command_params: {
        connector_id: 0,
        enable: false,
        transaction_id: null,
        reset_type: "Soft",
        message: 0,
        client_phone: null,
        firmware: "",
        files_count: 0,
        status: "disabled",
      },
      command: "Reset",
      error: {
        error: false,
        error_message: null,
      },
    };
  },
  watch: {
    current_tab: {
      handler(current) {
        this.filter_type = this.tabs[current].type;
      },
    },
    url_query: {
      handler() {
        this.load_params();
      },
    },
    params: {
      handler() {
        this.load_point();
      },
      deep: true,
    },
    date() {
      this.dateFormatted = this.formatDate(this.date);
    },
  },
  computed: {
    tabs() {
      return [
        {
          title: this.$t("points.events"),
          type: "event",
        },
        {
          title: this.$t("points.commands"),
          type: "command",
        },
      ];
    },
    commands() {
      return [
        { title: "Enable", text: this.$t("commands_text.Enable") },
        {
          title: "StartTransaction",
          text: this.$t("commands_text.StartTransaction"),
        },
        {
          title: "StopTransaction",
          text: this.$t("commands_text.StopTransaction"),
        },
        {
          title: "TransactionInfo",
          text: this.$t("commands_text.TransactionInfo"),
        },
        { title: "PointInfo", text: this.$t("commands_text.PointInfo") },
        { title: "Reset", text: this.$t("commands_text.Reset") },
        { title: "Unlock", text: this.$t("commands_text.Unlock") },
        {
          title: "TriggerMessage",
          text: this.$t("commands_text.TriggerMessage"),
        },
        { title: "Config", text: this.$t("commands_text.Config") },
        { title: "Update", text: this.$t("commands_text.Update") },
        { title: "Status", text: this.$t("commands_text.Status") },
      ];
    },
    events_headers() {
      return [
        {
          text: this.$t("headers.time"),
          align: "start",
          sortable: true,
          value: "event_at",
        },

        {
          text: this.$t("headers.event"),
          align: "start",
          sortable: true,
          value: "event_type",
        },
        {
          text: this.$t("headers.transaction"),
          align: "start",
          sortable: false,
          value: "charging",
          width: "15%",
        },
      ];
    },
    command_headers() {
      return [
        {
          text: this.$t("headers.time"),
          align: "start",
          sortable: true,
          value: "created_at",
        },
        {
          text: this.$t("headers.event"),
          align: "start",
          sortable: true,
          value: "command",
        },
        {
          text: this.$t("headers.transaction"),
          align: "start",
          sortable: false,
          value: "charging",
          width: "15%",
        },
        {
          text: this.$t("headers.client"),
          align: "start",
          sortable: false,
          value: "user.login",
          width: "15%",
        },
      ];
    },
    params() {
      return {
        date: this.date,
        event: this.event_filter,
        type: this.tabs[this.current_tab].type,
      };
    },
    point() {
      console.log(this.$store.getters.POINT_INFO)
      return this.$store.getters.POINT_INFO;
    },
    updated_time() {
      return moment(this.point.updated_at).locale("ru-RU").utc().format("LLL");
    },
    btn_text() {
      if (this.point.show_app) {
        return this.$t("points.mobile_available");
      }
      return this.$t("points.mobile_unavailable");
    },
    btn_color() {
      if (this.point.show_app) {
        return "green";
      }
      return "red";
    },
    isOnline() {
      return this.point.connected;
    },
    isOnline_text() {
      if (this.point.connected) {
        return "green";
      }
      return "red blink";
    },
    point_status() {
      let connected = "OFFLINE";
      if (this.point.connected) {
        connected = "ONLINE";
      }
      let point_status = this.$t("points.mobile_unavailable");
      switch (this.point.status) {
        case "repair":
          point_status = this.$t("points.status_repair");
          break;
        case "emergency":
          point_status = this.$t("points.status_emergency");
          break;
        case "online":
        case "available":
          point_status = this.$t("points.status_available");
          break;
        case "offline":
          point_status = this.$t("points.status_unavailable");
          break;
        case "reserved":
          point_status = this.$t("points.status_reservation");
          break;
        case "charging":
          point_status = this.$t("points.status_charging");
          break;
      }

      return `${connected}, ${point_status}`;
    },
    point_events() {
      return this.$store.getters.POINT_INFO.events;
    },
    point_command() {
      return this.$store.getters.POINT_INFO.commands;
    },
    available_events() {
      const events = this.$store.getters.AVAILABLE_EVENTS;
      return events.map((event) => {
        return { text: this.charging_status(event, 0), value: event };
      });
    },
    available_commands() {
      const events = this.$store.getters.AVAILABLE_COMMANDS;
      return events.map((command) => {
        return { text: this.command_text(command, 0), value: command };
      });
    },
    filter_select() {
      if (this.current_tab === 0) {
        return this.available_events;
      }
      return this.available_commands;
    },
    type_filter_text() {
      if (this.current_tab === 0) {
        return this.$t("points.event");
      }
      return this.$t("points.command");
    },
  },
  methods: {
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");

      return `${day}-${month}-${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [day, month, year] = date.split("-");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    load_params() {
      if (localStorage.getItem("point_info")) {
        let params = JSON.parse(localStorage.getItem("point_info"));
        this.date = params.query.date
            ? params.query.date
            : new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
                .toISOString()
                .substr(0, 10);
        this.event_filter = params.query.event ? params.query.event : null;
      } else {
        this.date = new Date(
            Date.now() - new Date().getTimezoneOffset() * 60000
        )
            .toISOString()
            .substr(0, 10);
        this.event_filter = null;
      }
    },
    load_point() {
      if (!this.maps_inited) {
        this.loading = true;
      }
      this.$store
          .dispatch("load_point_info", {
            point_id: this.$route.params.point_id,
            query: this.params,
          })
          .then(() => {})
          .finally(() => {
            this.loading = false;
            if (!this.maps_inited) {
              this.$nextTick(() => {
                this.init_map();
              });
            }
          });
    },

    init_map() {
      const myLatlng = { lat: this.point.latitude, lng: this.point.longitude };
      const mapContainer = this.$refs.googleMap;
      const map = new window.google.maps.Map(mapContainer, {
        zoom: 17,
        center: myLatlng,
      });
      const contentString =
          '<div id="content">' +
          '<div id="siteNotice">' +
          "</div>" +
          `<h3 id="firstHeading" class="" style="text-align: center">Зарядная станция ${this.point.point_number}</h3>` +
          '<div id="bodyContent">' +
          `<p>${this.point.address}</p>` +
          "</div>" +
          "</div>";
      const infowindow = new window.google.maps.InfoWindow({
        content: contentString,
      });

      const marker = new window.google.maps.Marker({
        position: myLatlng,
        map: map,
        optimized: true,
      });
      marker.addListener("click", () => {
        infowindow.open({
          anchor: marker,
          map,
          shouldFocus: true,
        });
      });
      this.maps_inited = true;
    },

    connector_color(connector) {
      if (connector.available) {
        return "green";
      }
      return "red";
    },
    con_text(connector) {
      switch (connector.status) {
        case "Disabled":
          return this.$t("points.con_disabled");
        case "Repair":
          return this.$t("points.con_Repair");
        case "Charging":
          return this.$t("points.con_Charging");
        case "Available":
          return this.$t("points.con_Available");
        case "Preparing":
          return this.$t("points.con_Preparing");
        case "Reserved":
          return this.$t("points.con_Reserved");
        case "Offline":
          return this.$t("points.con_Offline");
        case "Emergency":
          return this.$t("points.con_Emergency");
        default:
          return connector.status;
      }
    },
    evse_status(evse) {
      if (evse.available) {
        return "green";
      }
      return "red";
    },
    text_color() {
      return "red";
    },
    connector_type(connector_id) {
      let connector = this.point.evse[0].connectors.find(
          (con) => con.number === connector_id
      );
      if (connector === undefined) {
        return "";
      }
      return connector.connector_type;
    },
    charging_status(event, connector_id = 0, error_code = null) {
      let event_type = event;
      if (event.event_type !== undefined) {
        event_type = event.event_type;
      }
      switch (event_type) {
        case "Available":
          if (connector_id !== 0) {
            return this.$t("points.connector_status", {
              con_type: this.connector_type(connector_id),
              con_status: this.$t("points.con_Available"),
            });
          }
          return this.$t("points.point_status", {
            point_status: this.$t("points.status_available"),
          });
        case "Emergency":
          if (connector_id !== 0) {
            return this.$t("points.connector_status", {
              con_type: this.connector_type(connector_id),
              con_status: this.$t("points.con_Emergency"),
            });
          }
          return this.$t("points.point_status", {
            point_status: this.$t("points.status_emergency"),
          });
        case "Occupied":
          if (connector_id !== 0) {
            return this.$t("points.connector_status", {
              con_type: this.connector_type(connector_id),
              con_status: this.$t("points.con_Preparing"),
            });
          }
          return this.$t("points.point_status", {
            point_status: this.$t("points.status_occupied"),
          });
        case "Preparing":
          if (connector_id !== 0) {
            return this.$t("points.connector_status", {
              con_type: this.connector_type(connector_id),
              con_status: this.$t("points.con_Preparing"),
            });
          }
          return this.$t("points.point_status", {
            point_status: this.$t("points.status_preparing"),
          });
        case "Charging":
          if (connector_id !== 0) {
            return this.$t("points.connector_status", {
              con_type: this.connector_type(connector_id),
              con_status: this.$t("points.con_Charging"),
            });
          }
          return this.$t("points.point_status", {
            point_status: this.$t("points.status_charging"),
          });
        case "SuspendedEVSE":
          return this.$t("points.status_charging_paused_evse");
        case "SuspendedEV":
          return this.$t("points.status_charging_paused_ev");
        case "Finishing":
          if (connector_id !== 0) {
            return this.$t("points.connector_status", {
              con_type: this.connector_type(connector_id),
              con_status: this.$t("points.con_Finishing"),
            });
          }
          return this.$t("points.point_status", {
            point_status: this.$t("points.status_finishing"),
          });
        case "Reserved":
          if (connector_id !== 0) {
            return this.$t("points.connector_status", {
              con_type: this.connector_type(connector_id),
              con_status: this.$t("points.con_Reserved"),
            });
          }
          return this.$t("points.point_status", {
            point_status: this.$t("points.status_reservation"),
          });
        case "Unavailable":
          if (connector_id !== 0) {
            return this.$t("points.connector_status", {
              con_type: this.connector_type(connector_id),
              con_status: this.$t("points.con_unavailable"),
            });
          }
          return this.$t("points.point_status", {
            point_status: this.$t("points.status_unavailable"),
          });
        case "Faulted":
          if (connector_id !== 0) {
            return error_code != null
                ? this.$t("points.connector_status", {
                  con_type: this.connector_type(connector_id),
                }) +
                ":" +
                error_code
                : this.$t("points.connector_status", {
                  con_type: this.connector_type(connector_id),
                });
          }
          return error_code != null
              ? this.$t("points.point_status", {
                point_status: this.$t("points.error"),
              }) +
              ":" +
              error_code
              : this.$t("points.point_status", {
                point_status: this.$t("points.error"),
              });
        case "Online":
          return this.$t("points.point_status", {
            point_status: this.$t("points.status_online"),
          });
        case "Offline":
          return this.$t("points.point_status", {
            point_status: this.$t("points.status_offline"),
          });
      }
    },
    command_status(item) {
      const connector_id = item.connector_id;
      switch (item.command) {
        case "Enable":
          if (connector_id !== 0) {
            if (item.params.enable) {
              return (
                  this.$t("commands.enable", {
                    msg: this.$t("points.connector"),
                  }) + this.connector_type(connector_id)
              );
            }
            return (
                this.$t("commands.disable", {
                  msg: this.$t("points.connector"),
                }) + this.connector_type(connector_id)
            );
          }
          if (item.params.enable) {
            return this.$t("commands.enable", {
              msg: this.$t("headers.point"),
            });
          }
          return this.$t("commands.disable", { msg: this.$t("headers.point") });
        case "StartTransaction":
          return this.$t("commands.start_transaction", {
            transaction: item.params.transaction_id,
            con_type: this.connector_type(connector_id),
          });
        case "StopTransaction":
          return this.$t("commands.start_transaction", {
            transaction: item.params.transaction_id,
          });
        case "TransactionInfo":
          return this.$t("commands.update_transaction", {
            transaction: item.params.transaction_id,
          });
        case "PointInfo":
          return "Зарядка приостановлена машиной";
        case "Reset":
          if (item.params.reset_type === "Soft") {
            return this.$t("commands.soft_reset");
          }
          return this.$t("commands.hard_reset");
        case "Unlock":
          return this.$t("commands.unlock");
        case "TriggerMessage":
          return this.$t("commands.triggerMessage") + " " + item.params.message;
        case "Config":
          return this.$t("commands.Config");
        case "Update":
          return this.$t("commands.Update");
        case "Status":
          if (connector_id !== 0) {
            return this.$t("commands.Update", {
              con_type: this.connector_type(connector_id),
              status: item.params.status,
            });
          }
          return this.$t("commands.update_status");
      }
    },
    command_text(command, connector_id) {
      switch (command) {
        case "Enable":
          if (connector_id !== 0) {
            return this.$t("commands.enable_command", {
              cp_type_con: this.$t("commands.cp_type_con"),
            });
          }
          return this.$t("commands.enable_command", {
            cp_type_con: this.$t("commands.cp_type_point"),
          });
        case "StartTransaction":
          return this.$t("commands.StartTransaction");
        case "StopTransaction":
          return this.$t("commands.StopTransaction");
        case "TransactionInfo":
          return this.$t("commands.TransactionInfo");
        case "PointInfo":
          return this.$t("commands.PointInfo");
        case "Reset":
          return this.$t("commands.Reset");
        case "Unlock":
          if (connector_id !== 0) {
            return this.$t("commands.Unlock_con");
          }
          return this.$t("commands.Unlock");
        case "TriggerMessage":
          if (connector_id !== 0) {
            return "Коннектор недоступен";
          }
          return this.$t("commands.TriggerMessage");
        case "Config":
          if (connector_id !== 0) {
            return "Ошибка коннектора";
          }
          return this.$t("commands.Config");
        case "Update":
          return this.$t("commands.Update");
        case "Status":
          if (connector_id !== 0) {
            return this.$t("commands.status_con", {
              con_type: this.connector_type(connector_id),
            });
          }
          return this.$t("commands.status");
      }
    },
    connector_img(type) {
      let mode = this.$vuetify.theme.dark ? "dark" : "light";
      switch (type) {
        case "type2":
          return `plug-image--${mode} type2`;
        case "chademo":
          return `plug-image--${mode} chademo`;
        case "ccs2":
          return `plug-image--${mode} ccs2`;
        case "gbt":
          return `plug-image--${mode} gbt`;
      }
      return "";
    },
    show_point() {
      this.$store
          .dispatch("show_point", {
            point_number: this.point.point_number,
            show: !this.point.show_app,
          })
          .then(() => {
            this.load_point();
          });
    },
    go_back() {
      if (this.prevRoute == null) {
        return this.$router.push({ name: "points_list" });
      }
      return this.$router.push({
        name: this.prevRoute.name,
        params: this.prevRoute.params,
      });
    },
    show_command(item) {
      this.command = item.title;
      this.$store.dispatch("point_command_dialog", true);
    },
    send_command() {
      const params = {
        command: this.command,
        params: {},
      };
      switch (this.command) {
        case "Enable":
          params.params.enable = this.command_params.enable;
          params.params.connector_id = this.command_params.connector_id;
          break;
        case "Reset":
          params.params.reset_type = this.command_params.reset_type;
          break;
        case "StartTransaction":
          params.params.connector_id = this.command_params.connector_id;
          params.params.client_phone = this.command_params.client_phone;
          break;
        case "StopTransaction":
          params.params.transaction_id = this.command_params.transaction_id;
          break;
        case "TransactionInfo":
          break;
        case "PointInfo":
          break;
        case "TriggerMessage":
          params.params.message = this.command_params.message;
          params.params.connector_id = this.command_params.connector_id;
          break;
        case "Config":
          break;
        case "Unlock":
          params.params.connector_id = this.command_params.connector_id;
          break;
        case "Update":
          params.params.firmware = this.command_params.firmware;
          params.params.files_count = this.command_params.files_count;
          break;
        case "Status":
          params.params.status = this.command_params.status;
          params.params.connector_id = this.command_params.connector_id;
          break;
      }

      this.$store
          .dispatch("send_point_command", params)
          .then(() => {
            this.$store.dispatch("point_command_dialog", false);
            if (this.command === "StopTransaction") {
              this.$store.dispatch("load_transaction", {
                point_number: this.point.point_number,
              });
            }
            this.command_params = {
              connector_id: 0,
              enable: false,
              transaction_id: null,
              reset_type: "Soft",
              message: 0,
            };
          })
          .then(() => {
            this.load_point();
          })
          .catch((error) => {
            if (error.response.status === 402) {
              this.error.error = true;
              this.error.error_message = "Найдены неоплаченные зарядки";
            }
          });
    },
    edit_cp() {
      this.$store.dispatch("add_point_dialog", true);
    },
  },
};
</script>

<style lang="scss" scoped>
#map {
  height: 700px;
}
.divider {
  border-bottom: 1px solid gray;
  background-color: #dedede;
}
//.divider:last-child {
//  border-bottom: none;
//}

.icon {
  width: 48px;
  height: 48px;
}
.sprite.outlet {
  width: 48px;
  height: 48px;
}
.sprite {
  transform: scale(0.5);
  transform-origin: 0 0;
  margin: 0 auto;
}

//.offline {
//  color: white !important;
//}
.online {
  color: white !important;
}

.v-select {
  width: 650px;
}
</style>
